import axios from "axios";

export const httpClient = axios.create({
  baseURL: "https://api.myperfit.com/",
});

export const httpFirebase = axios.create({
  baseURL: "https://us-central1-perfit-internal-tools.cloudfunctions.net/",
});

export const httpIntegrations = axios.create({
  baseURL: "https://private-gw.prod.elb.myperfit.com/integrations/",
});

export const httpPeriodicReport = axios.create({
  baseURL: "https://private-gw.prod.elb.myperfit.com/accountmetrics/",
});

export const httpRefreshIntegration = axios.create({
  baseURL: "https://private-gw.prod.elb.myperfit.com/integrations/",
});

export const httpRefreshCDV = axios.create({
  baseURL: "https://private-gw.prod.elb.myperfit.com/orders/",
});

export const httpStopCampaign = axios.create({
  baseURL: "https://private-composer.prod.elb.myperfit.com/throttlemanager/",
});

export const httpValidateCategory = axios.create({
  baseURL: "https://private-gw.prod.elb.myperfit.com/integrations/tiendanube/",
});

export const httpValidateContact = axios.create({
  baseURL: "https://private-composer.prod.elb.myperfit.com/emailvalidator/",
});

export const httpValidateDomain = axios.create({
  baseURL: "https://private-gw.prod.elb.myperfit.com/fromauthvalidator/perfit/",
});

export const httpRelateAccount = axios.create({
  baseURL: "https://private-gw.prod.elb.myperfit.com/integrations/tiendanube/accountrelations/",
});

export const httpCreateCoupon = axios.create({
  baseURL: "https://pem-lb.myperfit.com/v2/coupons",
});

export const httpPEM = axios.create({
  baseURL: "https://pem-lb.myperfit.com/v2",
});