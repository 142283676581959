import {MutationTree} from "vuex";

import {State} from "./state";
import {MutationTypes} from "./mutation-types";

export type Mutations<S = State> = {
  [MutationTypes.SET_ISLOGGEDIN](state: S, isloggedIn: boolean): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_ISLOGGEDIN](state: State, isloggedIn: boolean) {
    state.isLoggedIn = isloggedIn;
  },
};
