import {VariantStyles} from "@/types/variants.type";
import {SizesClasses} from "@/types/sizes.type";

//Sizes
export const sizes: SizesClasses = {
  min: "px-0",
  xsmall: "py-0.5",
  small: "px-0.5 py-0.5",
  default: "px-1 py-0.5",
  large: "px-1.5 py-1",
  xlarge: "px-2 py-1",
};

export const fontSizes: SizesClasses = {
  min: "text-xs font-thin",
  xsmall: "text-xs font-light",
  small: "text-sm font-normal",
  default: "text-sm font-medium",
  large: "font-semibold",
  xlarge: "font-bold",
};

//Variants
interface Variants {
  primary: VariantStyles;
  secondary: VariantStyles;
  white: VariantStyles;
  valid: VariantStyles;
  error: VariantStyles;
  warning: VariantStyles;
  none: VariantStyles;
}
export const variants: Variants = {
  primary: {
    default: undefined,
    selected: undefined,
    classes: "text-gray-800 disabled:opacity-50",
  },
  secondary: {
    default: undefined,
    selected: undefined,
    classes: "text-gray-500 disabled:opacity-50",
  },
  white: {
    default: undefined,
    selected: undefined,
    classes: "text-white disabled:opacity-50",
  },
  valid: {
    default: undefined,
    selected: undefined,
    classes: "text-green-500",
  },
  error: {
    default: undefined,
    selected: undefined,
    classes: "text-red-500",
  },
  warning: {
    default: undefined,
    selected: undefined,
    classes: "text-yellow-500",
  },
  none: {
    default: undefined,
    selected: undefined,
    classes: undefined,
  },
};

export enum VariantKeys {
  primary = "primary",
  secondary = "secondary",
  white = "white",
  valid = "valid",
  error = "error",
  warning = "warning",
  none = "none",
}

export type VariantsType = keyof typeof VariantKeys;
