import {VariantStyles} from "@/types/variants.type";

interface GenericVariants {
  valid: VariantStyles;
  error: VariantStyles;
  warning: VariantStyles;
  none: VariantStyles;
}

export const genericVariants: GenericVariants = {
  valid: {
    default: undefined,
    selected: undefined,
    classes: "text-green-400",
  },
  error: {
    default: undefined,
    selected: undefined,
    classes: "text-red-400",
  },
  warning: {
    default: undefined,
    selected: undefined,
    classes: "text-yellow-400",
  },
  none: {
    default: undefined,
    selected: undefined,
    classes: undefined,
  },
};

export enum VariantKeys {
  error = "error",
  valid = "valid",
  warning = "warning",
  none = "none",
}

export type GenericVariantsType = keyof typeof VariantKeys;
