import {
  VariantKeys as VariantKeysInput,
  VariantsType as InputVariantsType,
} from "@/components/atoms/Input";
import { VariantKeys as VariantKeysLabel } from "@/components/atoms/Label";

//Variants
interface VariantsCombination {
  primary: {
    label: VariantKeysLabel;
    input: InputVariantsType;
  };
  alterPrimary: {
    label: VariantKeysLabel;
    input: InputVariantsType;
  };
  secondary: {
    label: VariantKeysLabel;
    input: InputVariantsType;
  };
  alterSecondary: {
    label: VariantKeysLabel;
    input: InputVariantsType;
  };
  valid: {
    label: VariantKeysLabel;
    input: InputVariantsType;
  };
  error: {
    label: VariantKeysLabel;
    input: InputVariantsType;
  };
  warning: {
    label: VariantKeysLabel;
    input: InputVariantsType;
  };
  none: {
    label: VariantKeysLabel;
    input: InputVariantsType;
  };
}

export const variantCombination: VariantsCombination = {
  primary: {
    label: VariantKeysLabel.primary,
    input: VariantKeysInput.primary,
  },
  alterPrimary: {
    label: VariantKeysLabel.primary,
    input: VariantKeysInput.alterPrimary,
  },
  secondary: {
    label: VariantKeysLabel.secondary,
    input: VariantKeysInput.secondary,
  },
  alterSecondary: {
    label: VariantKeysLabel.secondary,
    input: VariantKeysInput.alterSecondary,
  },
  valid: {
    label: VariantKeysLabel.valid,
    input: VariantKeysInput.valid,
  },
  error: {
    label: VariantKeysLabel.error,
    input: VariantKeysInput.error,
  },
  warning: {
    label: VariantKeysLabel.warning,
    input: VariantKeysInput.warning,
  },
  none: {
    label: VariantKeysLabel.none,
    input: VariantKeysInput.none,
  },
};

export enum VariantKeys {
  primary = "primary",
  alterPrimary = "primary",
  secondary = "secondary",
  alterSecondary = "secondary",
  valid = "valid",
  error = "error",
  warning = "warning",
  none = "none",
}

export type VariantsType = keyof typeof variantCombination;
