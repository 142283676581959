export interface SizesClasses {
  min: string;
  xsmall: string;
  small: string;
  default: string;
  large: string;
  xlarge: string;
}

export type SizesKeysType = keyof SizesClasses;

export enum SizesKeys {
  min = "min",
  xsmall = "xsmall",
  small = "small",
  default = "default",
  large = "large",
  xlarge = "xlarge",
}

export const sizesKeysArray: Array<SizesKeysType> = [
  "min",
  "xsmall",
  "small",
  "default",
  "large",
  "xlarge",
];

export type SizeCombination = Record<SizesKeys, Record<string, keyof typeof SizesKeys>>;
