import {createStore} from "vuex";
// {createLogger}
// import createPersistedState from "vuex-persistedstate";

import {
  store as config,
  ConfigStore,
  State as ConfigState,
} from "@/store/modules/Config";

import {
  store as Notification,
  NotificationStore,
  State as NotificationState,
} from "@/store/modules/Notifications";

export type RootState = {
  config: ConfigState;
  notification: NotificationState;
};

export type Store = ConfigStore<Pick<RootState, "config">> &
  NotificationStore<Pick<RootState, "notification">>;

// Plug in session storage based persistence
// plugins.push(createPersistedState({storage: window.sessionStorage}));

export const store = createStore({
  // plugins,
  modules: {
    config,
    Notification,
  },
});

export function useStore(): Store {
  return store as Store;
}
