import { VariantStyles } from "@/types/variants.type";
import { SizesClasses } from "@/types/sizes.type";

//Sizes
export const sizes: SizesClasses = {
  min: "px-0.5",
  xsmall: "px-2.5 py-1.5",
  small: "px-3 py-2",
  default: "px-4 py-2",
  large: "px-5 py-2",
  xlarge: "px-7 py-3",
};

export const fontSizes: SizesClasses = {
  min: "text-xs",
  xsmall: "text-xs",
  small: "text-sm",
  default: "text-sm font-semibold",
  large: "text-sm font-semibold",
  xlarge: "font-semibold",
};

export const loadingIconSizes: SizesClasses = {
  min: "h-2 w-2 mt-1",
  xsmall: "h-3 w-3 mt-0.5",
  small: "h-4 w-4 mt-0.5",
  default: "h-5 w-5 mt-0.5",
  large: "h-6 w-6",
  xlarge: "h-6 w-6",
};

//Variants
interface Variants {
  primary: VariantStyles;
  secondary: VariantStyles;
  none: VariantStyles;
}
export const variants: Variants = {
  primary: {
    default: "bg-green-perfit-800",
    selected: "bg-green-perfit-600",
    classes:
      "text-white hover:bg-cyan-perfit-500 disabled:opacity-50 focus:outline-none focus:ring focus:ring-green-perfit-200",
  },
  secondary: {
    default: "bg-cyan-perfit-800",
    selected: "bg-cyan-perfit-500",
    classes:
      "text-white hover:bg-green-perfit-600 disabled:opacity-50 focus:outline-none focus:ring focus:ring-cyan-perfit-200",
  },
  none: {
    default: undefined,
    selected: undefined,
    classes: undefined,
  },
};

export enum VariantKeys {
  primary = "primary",
  secondary = "secondary",
  none = "none",
}

export type VariantsType = keyof typeof VariantKeys;
