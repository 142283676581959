import { AxiosError } from "axios";
import { httpFirebase } from "@/services/axios.service";
import { PostSuggestionParams } from "@/services/models/suggesetion.model"

export const Send = async (
  PostSuggestion: PostSuggestionParams
): Promise<string> => {
  try {
    const resData = await httpFirebase.post<string>(
      "sendSuggestion",
      PostSuggestion
    );
    return resData.data;
  } catch (e) {
    return Promise.reject("Error");
    //if ((e as AxiosError)?.response) {
    //return (e as AxiosError)?.response?.data as PostLoginResponse;
    //} else {
    //}
  }
};
