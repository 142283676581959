import axios from "axios";
import { httpClient } from "@/services/axios.service";
import router from "@/router/index.router";
export const SetInterceptorLogIn = async (token: string): Promise<void> => {
  httpClient.defaults.headers.common["X-Auth-Token"] = token;
  axios.interceptors.response.use(
    function (response) {
      return Promise.resolve(response);
    },
    async function (e) {
      if (401 === e.response.status) {
        console.log("Unauthorized");
        // await Logout();
        router.push({ name: "Login" });
        return Promise.reject("Credenciales vencidas");
      } else {
        return Promise.reject(e);
      }
    }
  );
};
