import { VariantStyles } from "@/types/variants.type";
import { SizesClasses } from "@/types/sizes.type";

//Types
export enum Types {
  date = "date",
  datetimeLocal = "datetime-local",
  month = "month",
  week = "week",
  time = "time",
  email = "email",
  password = "password",
  number = "number",
  text = "text",
  search = "search",
  range = "range",
  url = "url",
}

//Sizes
export const sizes: SizesClasses = {
  min: "px-0.5",
  xsmall: "px-1 py-0.5",
  small: "px-2 py-1",
  default: "px-3 py-2",
  large: "px-4 py-3",
  xlarge: "px-5 py-4",
};

export const fontSizes: SizesClasses = {
  min: "text-xs",
  xsmall: "text-xs",
  small: "sm:text-sm",
  default: "sm:text-sm",
  large: "sm:text-sm",
  xlarge: "sm:text-md",
};

//Variants
interface Variants {
  primary: VariantStyles;
  alterPrimary: VariantStyles;
  secondary: VariantStyles;
  alterSecondary: VariantStyles;
  valid: VariantStyles;
  error: VariantStyles;
  warning: VariantStyles;
  none: VariantStyles;
}
export const variants: Variants = {
  primary: {
    default: undefined,
    selected: undefined,
    classes:
      "border-1 focus:outline-none focus:border-cyan-perfit-500 disabled:opacity-70 rounded-none",
  },
  alterPrimary: {
    default: undefined,
    selected: undefined,
    classes: "border focus:outline-none disabled:opacity-70 rounded-none",
  },
  secondary: {
    default: undefined,
    selected: undefined,
    classes:
      "border-1 focus:outline-none focus:border-green-perfit-500 disabled:opacity-70 rounded-none",
  },
  alterSecondary: {
    default: undefined,
    selected: undefined,
    classes: "border-1 focus:outline-none disabled:opacity-50 rounded-none",
  },
  valid: {
    default: undefined,
    selected: undefined,
    classes:
      "border-1 border-blue-500 focus:outline-none disabled:opacity-50 rounded-none",
  },
  error: {
    default: undefined,
    selected: undefined,
    classes:
      "border-1 border-green-500 focus:outline-none disabled:opacity-50 rounded-none",
  },
  warning: {
    default: undefined,
    selected: undefined,
    classes:
      "border-1 border-purple-500 focus:outline-none disabled:opacity-50 rounded-none",
  },
  none: {
    default: undefined,
    selected: undefined,
    classes: "focus:outline-none",
  },
};

export enum VariantKeys {
  primary = "primary",
  alterPrimary = "alterPrimary",
  secondary = "secondary",
  alterSecondary = "alterSecondary",
  valid = "valid",
  error = "error",
  warning = "warning",
  none = "none",
}

export type VariantsType = keyof typeof VariantKeys;
