import {store} from "../store/index.store";
import {ActionTypes as NotificationActionTypes} from "@/store/modules/Notifications/action-types";

export const ShowNotification = (
  title: string,
  message: string,
  type: "valid" | "info" | "error" | "warning",
  permanent = false,
  showTime?: number
): void => {
  store.dispatch(NotificationActionTypes.ADD_NOTIFICATION, {
    title: title,
    message: message,
    type: type,
    showTime: showTime,
    permanent: permanent,
  });
};
