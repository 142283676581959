import { Send as POSTSend } from "@/services/api/sendSuggestion.api";
import { InfoSuggestion } from "@/types/data.type";
import { PostSuggestionParams } from "@/services/models/suggesetion.model";

// Notification
import { ShowNotification } from "@/helpers/notification.helper";

export const SendEmail = async (credentials: InfoSuggestion): Promise<void> => {
  try {
    // Mapeo
    const SuggestionParams: PostSuggestionParams = {
      name: credentials.name,
      content: credentials.message,
    };
    await POSTSend(SuggestionParams);
    ShowNotification(
      "Sugerencia enviada",
      "Pronto nos estaremos contactando con vos para saber más tu sugerencia (:",
      "valid"
    );
  } catch (e) {
    ShowNotification(
      "Error",
      "Hubo un error al enviar la sugerencia. Intenta más tarde.",
      "error"
    );
    return Promise.reject({});
  }
};
