import {GetterTree} from "vuex";

import {RootState} from "@/store/index.store";

import {State} from "./state";

export type Getters = {
  isLoggedIn(state: State): boolean;
};

export const getters: GetterTree<State, RootState> & Getters = {
  isLoggedIn: (state) => state.isLoggedIn,
};
