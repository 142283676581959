//Variants
import { VariantStyles } from "@/types/variants.type";

interface Variants {
  primary: VariantStyles;
  none: VariantStyles;
}

export enum VariantKeys {
  primary = "primary",
  none = "none",
}

export const variants: Variants = {
  primary: {
    default: "text-gray-500",
    selected: "text-gray-500",
    classes: "text-gray-500",
  },
  none: {
    default: "text-yellow-500",
    selected: "text-yellow-500",
    classes: "text-yellow-500",
  },
};

export type VariantsType = keyof typeof VariantKeys;
