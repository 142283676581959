import {ActionTree, ActionContext} from "vuex";

// Aeslint-disable-next-line import/no-cycle
import {RootState} from "@/store/index.store";

import {state, State} from "./state";
import {Notification, NotificationWithID} from "@/types/notifications.type";
import {ActionTypes} from "./action-types";
import {Mutations} from "./mutations";
import {MutationTypes} from "./mutation-types";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [ActionTypes.ADD_NOTIFICATION](
    {commit}: AugmentedActionContext,
    payload: Notification
  ): void;
  [ActionTypes.REMOVE_NOTIFICATION](
    {commit}: AugmentedActionContext,
    payload: number
  ): void;
}

const GetLastNotificationID = () =>
  state.listOfNotifications[state.listOfNotifications.length - 1].id;

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionTypes.ADD_NOTIFICATION]({commit}, data: Notification) {
    const newID =
      state.listOfNotifications.length !== 0 ? GetLastNotificationID() + 1 : 0;

    const newNotification: NotificationWithID = {
      id: newID,
      ...data,
    };

    commit(MutationTypes.ADD_NOTIFICATION, newNotification);
  },
  [ActionTypes.REMOVE_NOTIFICATION]({commit}, id: number) {
    commit(MutationTypes.REMOVE_NOTIFICATION, id);
  },
};
