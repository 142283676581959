export const CookieTokenName = "InternalCookieToken";

interface GenerateExpirationDateParams {
  exDays?: number;
  exHours?: number;
  exMinutes?: number;
  exSeconds?: number;
  exMiliseconds?: number;
}

const GenerateExpirationDate = ({
  exDays = 0,
  exHours = 0,
  exMinutes = 0,
  exSeconds = 0,
  exMiliseconds = 0,
}: GenerateExpirationDateParams): string => {
  const date = new Date();
  date.setDate(date.getDate() + exDays);
  date.setHours(date.getHours() + exHours);
  date.setMinutes(date.getMinutes() + exMinutes);
  date.setSeconds(date.getSeconds() + exSeconds);
  date.setMilliseconds(date.getMilliseconds() + exMiliseconds);
  return date.toUTCString();
};

interface SetCookieTokenParams extends GenerateExpirationDateParams {
  cname: string;
  token: string;
}

export const SetCookieToken = ({
  cname,
  token,
  exDays,
  exHours,
  exMinutes,
  exSeconds,
  exMiliseconds,
}: SetCookieTokenParams): void => {
  const expires = GenerateExpirationDate({
    exDays,
    exHours,
    exMinutes,
    exSeconds,
    exMiliseconds,
  });
  document.cookie = cname + "=" + token + ";expires=" + expires + ";path=/";
};

export const DeleteCookieToken = (cname: string): void => {
  document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

export const getCookieToken = (cname: string): string => {
  const name = cname + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
