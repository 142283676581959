import {
  CreditCardIcon,
  DuplicateIcon,
  GlobeAltIcon,
  HomeIcon,
  PresentationChartLineIcon,
  RefreshIcon,
  UserGroupIcon,
  UserAddIcon,
  ShoppingCartIcon,
  XIcon,
  TicketIcon,
  ExclamationIcon,
  CheckCircleIcon,
  NewspaperIcon,
  ViewListIcon,
  UserIcon
} from "@heroicons/vue/outline";
import { Component } from "vue";

// Interface
interface RouteNamesInterface {
  name: string;
  path: string;
  icon?: Component; // ACá antes era Render function con este import { RenderFunction } from "vue-demi";
}

type routesObject = Record<string, RouteNamesInterface>;
type arrayRoutes = Array<RouteNamesInterface>;

export const RouteNames: routesObject = {
  home: {
    name: "Home",
    path: "/",
    icon: HomeIcon,
  },
  login: {
    name: "Login",
    path: "/login",
  },
  alertAutomation: {
    name: "Alerta Automations",
    path: "/alert-automation",
    icon: ExclamationIcon,
  },
  createCoupon: {
    name: "Crear Cupón",
    path: "/crear-cupon",
    icon: TicketIcon,
  },
  stopCampaign: {
    name: "Detener Campaña",
    path: "/detener-campana",
    icon: XIcon,
  },
  deleteAccountRelationship: {
    name: "Eliminar relaciones",
    path: "/eliminar-relaciones",
    icon: NewspaperIcon,
  },
  duplicateAutomation: {
    name: "Duplicar Automation",
    path: "/duplicar-automation",
    icon: DuplicateIcon,
  },
  periodicReports: {
    name: "Generar Reporte",
    path: "/reports",
    icon: PresentationChartLineIcon,
  },
  linkDiscount: {
    name: "Link de Descuento",
    path: "/link-descuento",
    icon: CreditCardIcon,
  },
  orderInfo: {
    name: "Obtener Info de una Orden",
    path: "/order-info",
    icon: ShoppingCartIcon,
  },
  contactRecovery: {
    name: "Recuperar contactos",
    path: "/contact-recovery",
    icon: UserIcon,
  },
  listRecovery: {
    name: "Recuperar listas",
    path: "/list-recovery",
    icon: ViewListIcon,
  },
  refreshCDV: {
    name: "Refrescar CDV",
    path: "/refresh-cdv",
    icon: UserGroupIcon,
  },
  refreshIntegration: {
    name: "Refrescar Integraciones",
    path: "/integrations",
    icon: RefreshIcon,
  },
  validateCategory: {
    name: "Validador de Categorías",
    path: "/validate-category",
    icon: CheckCircleIcon,
  },
  validateContact: {
    name: "Validador de Contacto",
    path: "/validate-contact",
    icon: UserAddIcon,
  },
  validateDomain: {
    name: "Validador de Dominio",
    path: "/validate-domain",
    icon: GlobeAltIcon,
  },
  pageNotFound: {
    name: "pageNotFound",
    path: "/pageNotFound",
  },
};

export const routesWithoutDrawer: Array<string> = [
  RouteNames.login.name,
  RouteNames.pageNotFound.name,
];

export const routesSidebar: arrayRoutes = [
  RouteNames.home,
  RouteNames.alertAutomation,
  RouteNames.createCoupon,
  RouteNames.stopCampaign,
  RouteNames.deleteAccountRelationship,
  RouteNames.duplicateAutomation,
  RouteNames.periodicReports,
  RouteNames.linkDiscount,
  RouteNames.orderInfo,
  RouteNames.contactRecovery,
  RouteNames.listRecovery,
  RouteNames.refreshCDV,
  RouteNames.refreshIntegration,
  RouteNames.validateCategory,
  RouteNames.validateContact,
  RouteNames.validateDomain,
];
