import { SizeCombination } from "@/types/sizes.type";
import {
  VariantKeys as VariantKeysParagraph,
  VariantsType as ParagraphVariantsType,
} from "@/components/atoms/Paragraph";
import { VariantKeys as VariantKeysTitle } from "@/components/atoms/Title";

//Variants
interface VariantsCombination {
  primary: {
    title: VariantKeysTitle;
    paragraph: ParagraphVariantsType;
  };
  secondary: {
    title: VariantKeysTitle;
    paragraph: ParagraphVariantsType;
  };
  valid: {
    title: VariantKeysTitle;
    paragraph: ParagraphVariantsType;
  };
  error: {
    title: VariantKeysTitle;
    paragraph: ParagraphVariantsType;
  };
  warning: {
    title: VariantKeysTitle;
    paragraph: ParagraphVariantsType;
  };
  none: {
    title: VariantKeysTitle;
    paragraph: ParagraphVariantsType;
  };
}

export const sizeCombination: SizeCombination = {
  min: {
    title: "min",
    paragraph: "min",
  },
  xsmall: {
    title: "xsmall",
    paragraph: "min",
  },
  small: {
    title: "large",
    paragraph: "xsmall",
  },
  default: {
    title: "default",
    paragraph: "default",
  },
  large: {
    title: "large",
    paragraph: "small",
  },
  xlarge: {
    title: "xlarge",
    paragraph: "large",
  },
};

export const variantCombination: VariantsCombination = {
  primary: {
    title: VariantKeysTitle.primary,
    paragraph: VariantKeysParagraph.primary,
  },
  secondary: {
    title: VariantKeysTitle.secondary,
    paragraph: VariantKeysParagraph.secondary,
  },
  valid: {
    title: VariantKeysTitle.valid,
    paragraph: VariantKeysParagraph.valid,
  },
  error: {
    title: VariantKeysTitle.error,
    paragraph: VariantKeysParagraph.error,
  },
  warning: {
    title: VariantKeysTitle.warning,
    paragraph: VariantKeysParagraph.warning,
  },
  none: {
    title: VariantKeysTitle.none,
    paragraph: VariantKeysParagraph.none,
  },
};

export enum VariantKeys {
  primary = "primary",
  secondary = "secondary",
  valid = "valid",
  error = "error",
  warning = "warning",
  none = "none",
}

export type VariantsType = keyof typeof variantCombination;
