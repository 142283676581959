import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/Home.vue";
import { RouteNames } from "@/types/router.type";
import { useStore } from "@/store/index.store";

const routes: Array<RouteRecordRaw> = [
  {
    path: RouteNames.home.path,
    name: RouteNames.home.name,
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: RouteNames.login.path,
    name: RouteNames.login.name,
    component: () =>
      import(/* webpackChunkName: "Login view" */ "@/views/Login.vue"),
    beforeEnter: async (_, __, next) => {
      const store = useStore();
      !store.getters.isLoggedIn ? next() : next({ name: RouteNames.home.name });
    },
  },
  {
    path: RouteNames.alertAutomation.path,
    name: RouteNames.alertAutomation.name,
    component: () =>
      import(
        /* webpackChunkName: "AlertAutomation view" */ "@/views/AlertAutomation.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: RouteNames.stopCampaign.path,
    name: RouteNames.stopCampaign.name,
    component: () =>
      import(
        /* webpackChunkName: "StopCampaign view" */ "@/views/StopCampaign.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: RouteNames.deleteAccountRelationship.path,
    name: RouteNames.deleteAccountRelationship.name,
    component: () =>
      import(
        /* webpackChunkName: "DeleteAccountRelationship view" */ "@/views/DeleteAccountRelationship.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: RouteNames.duplicateAutomation.path,
    name: RouteNames.duplicateAutomation.name,
    component: () =>
      import(
        /* webpackChunkName: "DuplicateAutomation view" */ "@/views/DuplicateAutomation.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: RouteNames.linkDiscount.path,
    name: RouteNames.linkDiscount.name,
    component: () =>
      import(
        /* webpackChunkName: "LinkDiscount view" */ "@/views/LinkDiscount.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: RouteNames.orderInfo.path,
    name: RouteNames.orderInfo.name,
    component: () =>
      import(/* webpackChunkName: "orderInfo view" */ "@/views/OrderInfo.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: RouteNames.contactRecovery.path,
    name: RouteNames.contactRecovery.name,
    component: () =>
      import(
        /* webpackChunkName: "refreshCDV view" */ "@/views/ContactRecovery.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: RouteNames.listRecovery.path,
    name: RouteNames.listRecovery.name,
    component: () =>
      import(
        /* webpackChunkName: "refreshCDV view" */ "@/views/ListRecovery.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: RouteNames.refreshCDV.path,
    name: RouteNames.refreshCDV.name,
    component: () =>
      import(
        /* webpackChunkName: "refreshCDV view" */ "@/views/RefreshCDV.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: RouteNames.refreshIntegration.path,
    name: RouteNames.refreshIntegration.name,
    component: () =>
      import(
        /* webpackChunkName: "RefreshIntegration view" */ "@/views/RefreshIntegration.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: RouteNames.periodicReports.path,
    name: RouteNames.periodicReports.name,
    component: () =>
      import(
        /* webpackChunkName: "PeriodicReports view" */ "@/views/PeriodicReports.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: RouteNames.validateCategory.path,
    name: RouteNames.validateCategory.name,
    component: () =>
      import(
        /* webpackChunkName: "ValidateContact view" */ "@/views/ValidateCategory.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: RouteNames.validateContact.path,
    name: RouteNames.validateContact.name,
    component: () =>
      import(
        /* webpackChunkName: "ValidateContact view" */ "@/views/ValidateContact.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: RouteNames.validateDomain.path,
    name: RouteNames.validateDomain.name,
    component: () =>
      import(
        /* webpackChunkName: "ValidateDomain view" */ "@/views/ValidateDomain.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: RouteNames.createCoupon.path,
    name: RouteNames.createCoupon.name,
    component: () =>
      import(
        /* webpackChunkName: "CreateCoupon view" */ "@/views/CreateCoupon.vue"
      ),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const store = useStore();

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    try {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next({ name: RouteNames.login.name });
      }
    } catch (e) {
      console.log(e);
    }
  } else {
    next();
  }
});

export default router;
