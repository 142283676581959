import { GetterTree } from "vuex";

// Aeslint-disable-next-line import/no-cycle
import { RootState } from "@/store/index.store";

import { State, state } from "./state";

//Types
import { NotificationWithID } from "@/types/notifications.type";

export type Getters = {
  GetListOfNotifications(): Array<NotificationWithID>;
};
export const getters: GetterTree<State, RootState> & Getters = {
  GetListOfNotifications: () => {
    return state.listOfNotifications;
  },
};
