import { VariantStyles } from "@/types/variants.type";
import { SizesClasses } from "@/types/sizes.type";

export const fontSizes: SizesClasses = {
  min: "text-xs",
  xsmall: "text-sm",
  small: "text-base",
  default: "text-lg font-semibold",
  large: "text-xl font-semibold",
  xlarge: "text-2xl font-bold",
};

//Variants
interface Variants {
  primary: VariantStyles;
  secondary: VariantStyles;
  white: VariantStyles;
  valid: VariantStyles;
  error: VariantStyles;
  warning: VariantStyles;
  none: VariantStyles;
}
export const variants: Variants = {
  primary: {
    default: undefined,
    selected: undefined,
    classes: "text-black-perfit-800",
  },
  secondary: {
    default: undefined,
    selected: undefined,
    classes: "text-black-perfit-500",
  },
  white: {
    default: undefined,
    selected: undefined,
    classes: "text-white",
  },
  valid: {
    default: undefined,
    selected: undefined,
    classes: "text-green-perfit-500",
  },
  error: {
    default: undefined,
    selected: undefined,
    classes: "text-red-perfit-500",
  },
  warning: {
    default: undefined,
    selected: undefined,
    classes: "text-yellow-perfit-600",
  },
  none: {
    default: undefined,
    selected: undefined,
    classes: undefined,
  },
};

export enum VariantKeys {
  primary = "primary",
  secondary = "secondary",
  white = "white",
  valid = "valid",
  error = "error",
  warning = "warning",
  none = "none",
}

export type VariantsType = keyof typeof VariantKeys;
