import {MutationTree} from "vuex";
import {MutationTypes} from "./mutation-types";
import {State} from "./state";
import {NotificationWithID} from "@/types/notifications.type";

import {remove} from "lodash";

export type Mutations<S = State> = {
  [MutationTypes.ADD_NOTIFICATION](
    state: S,
    notification: NotificationWithID
  ): void;
  [MutationTypes.REMOVE_NOTIFICATION](
    state: S,
    listOfNotifications: number
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.ADD_NOTIFICATION](state, notification: NotificationWithID) {
    state.listOfNotifications.push(notification);
  },
  [MutationTypes.REMOVE_NOTIFICATION](state, id: number) {
    remove(state.listOfNotifications, (notification) => notification.id == id);
  },
};
