import {ActionTree, ActionContext} from "vuex";

import {RootState} from "@/store/index.store";

import {State} from "./state";
import {Mutations} from "./mutations";
import {ActionTypes} from "./action-types";
import {MutationTypes} from "./mutation-types";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [ActionTypes.SET_ISLOGGEDIN](
    {commit}: AugmentedActionContext,
    payload: boolean
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionTypes.SET_ISLOGGEDIN]({commit}, data: boolean) {
    commit(MutationTypes.SET_ISLOGGEDIN, data);
  },
};
