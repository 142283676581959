import { VariantStyles } from "@/types/variants.type";
import { SizesClasses } from "@/types/sizes.type";

//Sizes
export const sizes: SizesClasses = {
  min: "px-0.5",
  xsmall: "px-2.5 py-1.5",
  small: "px-3 py-2",
  default: "px-4 py-2",
  large: "px-5 py-2",
  xlarge: "px-7 py-3",
};

export const fontSizes: SizesClasses = {
  min: "text-xs",
  xsmall: "text-xs",
  small: "text-sm",
  default: "text-sm font-semibold",
  large: "text-sm font-semibold",
  xlarge: "font-semibold",
};

//Variants
interface VariantAlert extends VariantStyles {
  icon: string | undefined;
  header: string | undefined;
  body: string | undefined;
}

interface VariantsCombination {
  primary: VariantAlert;
  secondary: VariantAlert;
  valid: VariantAlert;
  error: VariantAlert;
  warning: VariantAlert;
  info: VariantAlert;
  none: VariantAlert;
}

export const variants: VariantsCombination = {
  primary: {
    icon: "text-green-perfit-500",
    default: "bg-black-50",
    selected: undefined,
    header: "text-green-800",
    body: undefined,
    classes: "rounded border-l-4 border-green-perfit-500",
  },
  secondary: {
    icon: "text-cyan-perfit-500",
    default: "bg-black-50",
    selected: undefined,
    header: undefined,
    body: undefined,
    classes: "rounded border-l-4 border-cyan-perfit-500",
  },
  error: {
    icon: "text-red-perfit-500",
    default: "bg-black-50",
    selected: undefined,
    header: undefined,
    body: undefined,
    classes: "rounded border-l-4 border-red-perfit-500",
  },
  warning: {
    icon: "text-yellow-perfit-500",
    default: "bg-black-50",
    selected: undefined,
    header: undefined,
    body: undefined,
    classes: "rounded border-l-4 border-yellow-perfit-500",
  },
  valid: {
    icon: "text-blue-perfit-500",
    default: "bg-black-50",
    selected: undefined,
    header: undefined,
    body: undefined,
    classes: "rounded border-l-4 border-blue-perfit-500",
  },
  info: {
    icon: "text-aqua-perfit-500",
    default: "bg-black-50",
    selected: undefined,
    header: undefined,
    body: undefined,
    classes: "rounded border-l-4 border-aqua-perfit-500",
  },
  none: {
    icon: undefined,
    default: undefined,
    selected: undefined,
    header: undefined,
    body: undefined,
    classes: undefined,
  },
};

export enum VariantKeys {
  primary = "primary",
  secondary = "secondary",
  error = "error",
  warning = "warning",
  valid = "valid",
  info = "info",
  none = "none",
}

export type VariantsType = keyof typeof VariantKeys;
